import React from "react"
import { Col, Container, Row } from "react-bootstrap";
import GenericPage from "../components/generic-page";

export default function ContactPage() {
  return (<GenericPage title={"Contact"}>

    <div>
      <Container>
        <Row>
          <Col>
            <h2>{"Sharon Newman"}</h2>
            <em>{"Photographer"}</em>
          </Col>
        </Row>
        <hr/>
        <Row className="align-items-center">
          <Col xs={3}>
            <label>{"Phone"}</label>
          </Col>
          <Col xs="auto">
            <a href="tel:+61424166430">{"0424166430"}</a>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={3}>
            <label>{"Email"}</label>
          </Col>
          <Col xs="auto">
            <a href="mailto:sharon@snphotography.com.au">{"sharon@snphotography.com.au"}</a>
          </Col>
        </Row>
        <hr/>
      </Container>
    </div>
  </GenericPage>);
}
